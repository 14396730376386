import { React, useState, useEffect, useRef } from "react";
import Header from "./Header.jsx";
import KeyFeatures from "./keyFeatures.jsx";
import {
  Container,
  Typography,
  Box,
  Fade,
  Divider,
  Button,
} from "@mui/material";
import Footer from "./Footer.jsx";
import FAQ from "./FAQ.jsx";

const HomePage = () => {
  const [zoomIn, setZoomIn] = useState(false);

  useEffect(() => {
    setZoomIn(true);
  }, []);

  const useInView = (options) => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing after it's in view
        }
      }, options);

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        if (ref.current) observer.unobserve(ref.current);
      };
    }, [options]);

    return [ref, isVisible];
  };

  const [featuresRef, featuresInView] = useInView({ threshold: 0.1 });
  const [faqRef, faqInView] = useInView({ threshold: 0.1 });

  return (
    <div>
      <Box
        sx={{
          backgroundColor: "#F8F6F4", // Full-width background color
          minHeight: "100vh", // Ensures background extends to full height of the viewport
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Container maxWidth="md" sx={{ paddingTop: 8, paddingBottom: 8 }}>
          <Header />

          <Box
            id="hero"
            sx={{
              textAlign: "center",
              paddingTop: 10,
            }}
          >
            <Fade in={zoomIn} timeout={1000}>
              <Typography
                variant="h4"
                color="black"
                fontWeight="700"
                gutterBottom
              >
                Make feedback easy using AI.
              </Typography>
            </Fade>

            <Fade in={zoomIn} timeout={1000}>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{ marginBottom: 4, maxWidth: 600, marginX: "auto" }}
              >
                Want to receive constructive feedback from your friends or
                colleagues? Our platform uses AI to summarize feedback so that
                feedback givers feel confident, and feedback receivers can
                quickly understand key points.
              </Typography>
            </Fade>

            <Fade in={zoomIn} timeout={1000}>
              <Button
                variant="contained"
                sx={{
                  padding: "10px 30px",
                  borderRadius: "50px",
                  textTransform: "none",
                  fontSize: "1rem",
                  fontWeight: "500",
                  backgroundColor: "primary", // Customize color if needed
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#1565c0", // Darker color on hover
                  },
                  marginBottom: 4,
                }}
                href="/request"
              >
                Request Feedback Now
              </Button>
            </Fade>
          </Box>

          <Box
            id="hero-image"
            sx={{
              textAlign: "center",
            }}
          >
            <Fade in={zoomIn} timeout={1000}>
              <Box
                component="img"
                sx={{
                  maxWidth: { xs: 330, md: 400 }, // Set the maximum width for responsiveness
                  height: "auto", // Let the height adjust automatically to maintain aspect ratio
                  borderRadius: 2,
                  boxShadow: 3,
                  marginBottom: 5,
                  border: "2px solid black",
                  textAlign: "center",
                }}
                alt="Sincero feedback request form"
                src="/assets/images/hero_image.png" // Make sure the path is correct
              />
            </Fade>
          </Box>

          <Fade in={featuresInView} timeout={1000}>
            <Box ref={featuresRef}>
              <KeyFeatures />
            </Box>
          </Fade>

          <Fade in={faqInView} timeout={1000}>
            <Box ref={faqRef}>
              <FAQ />
            </Box>
          </Fade>

          <Divider />
          <Footer />
        </Container>
      </Box>
    </div>
  );
};

export default HomePage;
