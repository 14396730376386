import { React, useState } from "react";

import { db } from "../firebase.js";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useNavigate, Link } from "react-router-dom";

import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  Tooltip,
  IconButton,
  Fade,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import BrandLogo from "./brandLogo.jsx";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckIcon from "@mui/icons-material/Check";

const FeedbackRequestPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [question, setQuestion] = useState("");
  const [saveExactWordings, setSaveExactWordings] = useState(false);
  const [errors, setErrors] = useState({});
  const [confirmEmail, setConfirmEmail] = useState("");

  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!name.trim()) newErrors.name = "Name is required";
    if (!email.trim()) {
      newErrors.email = "Email is required";
    } else if (!validateEmail(email)) {
      newErrors.email = "Email is invalid";
    }

    if (!confirmEmail.trim()) {
      newErrors.confirmEmail = "Confirm Email is required";
    } else if (email !== confirmEmail) {
      newErrors.confirmEmail = "Emails do not match";
    }

    if (!question.trim()) newErrors.question = "Question is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const docRef = await addDoc(collection(db, "feedback_request"), {
          name,
          email,
          question,
          save_exact_feedback: saveExactWordings,
          create_time: new Date().toISOString(),
        });

        console.log("Document written with ID: ", docRef.id);

        sessionStorage.setItem("submittedEmail", email);
        navigate(`/request/success/${docRef.id}`);

        setName("");
        setEmail("");
        setQuestion("");
        setSaveExactWordings(false);
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    }
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        backgroundColor: "#F8F6F4",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        alt="Brand logo"
        sx={{
          position: "absolute",
          top: "20px",
          left: "20px",
          width: "40px",
          height: "40px",
        }}
      >
        <Link to="/">
          <BrandLogo
            sx={{ height: 50, marginRight: "10px", cursor: "pointer" }}
          />
        </Link>
      </Box>

      <Fade in={true} timeout={700}>
        <Container maxWidth="sm" sx={{ textAlign: "center" }}>
          <Typography
            variant="h5"
            color="black"
            sx={{
              marginBottom: 2,
              fontWeight: "700",
              fontSize: { xs: "1.4rem", md: "1.8rem" },
              color: "black",
            }}
          >
            Create Feedback Request
          </Typography>
          <Link onClick={handleClickOpen} sx={{ cursor: "pointer" }}>
            <Typography
              variant="h5"
              color="black"
              sx={{
                marginBottom: 2,
                fontWeight: "400",
                fontSize: { xs: "0.8rem", md: "0.8rem" },
                color: "grey.700",
              }}
            >
              How does it work?
            </Typography>
          </Link>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
              "& .MuiDialog-paper": {
                backgroundColor: "#F8F6F4", // Set your desired background color
                borderRadius: "20px",
              },
            }}
          >
            <DialogTitle id="alert-dialog-title">
              {"How does it work?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Typography variant="body1">1. Ask a question.</Typography>
                <Typography variant="body1">
                  2. Choose if you want to view the original feedback or only
                  the summary.
                </Typography>
                <Typography variant="body1">
                  3. A unique link will be generated that you can share with
                  others.
                </Typography>
                <Typography variant="body1">
                  4. People can then provide feedback anonymously through this
                  link.
                </Typography>
                <Typography variant="body1">
                  5. After three days, you will receive a summary of all
                  responses (and the responses if you choose so).
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>

          <form onSubmit={handleSubmit} noValidate>
            {/* Name Field */}
            <Box sx={{ marginBottom: 2 }}>
              <TextField
                label="Name"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={!!errors.name}
                helperText={errors.name}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "20px",
                }}
                slotProps={{
                  input: {
                    disableUnderline: true,
                    sx: {
                      borderRadius: "20px",
                      border: "1px solid transparent",
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  },
                  inputLabel: {
                    sx: {
                      color: "grey.500",
                    },
                  },
                }}
              />
            </Box>

            {/* Email Field */}
            <Box sx={{ marginBottom: 2 }}>
              <TextField
                label="Email"
                fullWidth
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!errors.email}
                helperText={errors.email}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "20px",
                }}
                slotProps={{
                  input: {
                    disableUnderline: true,
                    sx: {
                      borderRadius: "20px",
                      border: "1px solid transparent",
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  },
                  inputLabel: {
                    sx: {
                      color: "grey.500",
                    },
                  },
                }}
              />
            </Box>

            {/* Confirm Email Field */}
            <Box sx={{ marginBottom: 2 }}>
              <TextField
                label="Confirm Email"
                fullWidth
                type="email"
                value={confirmEmail}
                onChange={(e) => setConfirmEmail(e.target.value)}
                error={!!errors.confirmEmail}
                helperText={errors.confirmEmail}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "20px",
                }}
                slotProps={{
                  input: {
                    disableUnderline: true,
                    sx: {
                      borderRadius: "20px",
                      border: "1px solid transparent",
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  },
                  inputLabel: {
                    sx: {
                      color: "grey.500",
                    },
                  },
                }}
              />
            </Box>

            <Box sx={{ marginBottom: 2 }}>
              <TextField
                label="Question"
                fullWidth
                multiline
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                error={!!errors.question}
                helperText={errors.question}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "20px",
                }}
                slotProps={{
                  input: {
                    disableUnderline: true,
                    sx: {
                      borderRadius: "20px",
                      border: "1px solid transparent",
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  },
                  inputLabel: {
                    sx: {
                      color: "grey.500",
                    },
                  },
                }}
              />
            </Box>

            <Box
              sx={{
                marginBottom: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={saveExactWordings}
                    onChange={(e) => setSaveExactWordings(e.target.checked)}
                    color="primary"
                  />
                }
                label="Keep original feedback"
              />
              <Tooltip
                title="If selected, you will receive all the feedback at their original wordings together with AI summarization. However, all feedback remains anonymous."
                componentsProps={{
                  tooltip: {
                    sx: {
                      fontSize: "0.8rem", // Adjust font size for tooltip content
                      padding: "10px", // Adjust padding to make tooltip area larger
                    },
                  },
                }}
              >
                <IconButton sx={{ padding: 0 }}>
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>

            <Button
              type="submit"
              variant="contained"
              sx={{
                padding: 1.5,
                borderRadius: "50px",
                textTransform: "none",
                width: "100px",
                backgroundColor: "primary", // Set custom background color
                color: "white",
              }}
            >
              <CheckIcon />
            </Button>
          </form>
        </Container>
      </Fade>
    </Box>
  );
};

export default FeedbackRequestPage;
