import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";
import HomePage from "./components/homePage.jsx";
import RequestSuccessPage from "./components/requestSuccessPage.jsx";
import AnswerPage from "./components/answerPage.jsx";
import FAQ from "components/FAQ.jsx";
import FeedbackRequestPage from "components/feedbackRequestPage.jsx";
import BusinessAnswerPage from "components/businessAnswerPage.jsx";
import BusinessFeedbackRequestPage from "components/businessFeedbackRequestPage.jsx";

import theme from "./theme.js";
import { ThemeProvider } from "@mui/material/styles";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/request/success/:requestId"
            element={<RequestSuccessPage />}
          />
          <Route path="/answer/:requestId" element={<AnswerPage />} />
          <Route
            path="business/answer/:requestId"
            element={<BusinessAnswerPage />}
          />
          <Route path="/request" element={<FeedbackRequestPage />} />
          <Route
            path="business/request"
            element={<BusinessFeedbackRequestPage />}
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
