// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyClEbNsEtTf4vqybAOdy7WCvZPQvtkV0Yk",
  authDomain: "sincero-67167.firebaseapp.com",
  projectId: "sincero-67167",
  storageBucket: "sincero-67167.appspot.com",
  messagingSenderId: "835560368746",
  appId: "1:835560368746:web:52dd84f1f1a394a1aeb813",
  measurementId: "G-V0SGDZ0FM2",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
