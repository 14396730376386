import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../firebase";
import { doc, getDoc, setDoc, arrayUnion } from "firebase/firestore";
import { format, addDays } from "date-fns";
import { Helmet } from "react-helmet";
import BrandLogo from "./brandLogo.jsx";
import Link from "@mui/material/Link";

// Import Material UI Components
import {
  TextField,
  Button,
  Typography,
  Box,
  Container,
  Fade,
} from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";

const AnswerPage = () => {
  const { requestId } = useParams(); // Get the document ID from the URL
  const [questionData, setQuestionData] = useState(null); // Store question details
  const [answer, setAnswer] = useState(""); // Store user input (answer)
  const [submitted, setSubmitted] = useState(false); // Track submission status
  const [loading, setLoading] = useState(true); // Track loading state

  // Fetch the question details when the page loads
  useEffect(() => {
    const fetchQuestionData = async () => {
      setLoading(true);
      const docRef = doc(db, "feedback_request", requestId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setQuestionData(docSnap.data());
        setLoading(false);
      } else {
        console.log("No such document!");
      }
    };

    fetchQuestionData();
  }, [requestId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const answerData = {
        answer: answer,
        answer_time: new Date().toISOString(),
      };

      const docRef = doc(db, "feedback_answer", requestId);

      await setDoc(
        docRef,
        {
          answers: arrayUnion(answerData),
        },
        { merge: true }
      );

      setSubmitted(true);
      setAnswer("");
    } catch (error) {
      console.error("Error submitting answer: ", error);
    }
  };

  const requestCreateDate = questionData?.create_time
    ? new Date(questionData.create_time)
    : null;

  const resultDate = requestCreateDate
    ? format(addDays(requestCreateDate, 3), "PPPP")
    : "Loading...";

  const maxCharacters = 1000;

  const currentDomain = window.location.origin;

  if (submitted) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          backgroundColor: "#F8F6F4",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          alt="Brand logo"
          sx={{
            position: "absolute",
            top: "20px",
            left: "20px",
            width: "40px",
            height: "40px",
          }}
        >
          <Link to="/">
            <BrandLogo
              sx={{ height: 50, marginRight: "10px", cursor: "pointer" }}
            />
          </Link>
        </Box>
        <Fade in={submitted} timeout={700}>
          <Container sx={{ textAlign: "center" }}>
            <Typography
              variant="h5"
              color="black"
              sx={{
                marginBottom: 2,
                fontWeight: "700",
                fontSize: { xs: "1.4rem", md: "1.8rem" },
                color: "black",
              }}
            >
              All good, thanks! 🫡
            </Typography>
            <Link href="/request" target="_blank" underline="always">
              <Typography
                variant="body1"
                sx={{
                  color: "primary",
                  fontSize: { xs: "0.8rem", md: "1rem" },
                }}
              >
                You can also create your own feedback request.
              </Typography>
            </Link>
          </Container>
        </Fade>
      </Box>
    );
  }

  return (
    <div>
      {questionData && (
        <Helmet>
          <title>Give {questionData.name} feedback anonymously</title>
          <meta
            name="description"
            content={`Provide anonymous feedback to ${questionData.name} through Sincero.`}
          />
          <meta
            property="og:title"
            content={`Give feedback to ${questionData.name}`}
          />
          <meta
            property="og:description"
            content="Provide your honest feedback anonymously and securely through Sincero."
          />
          <meta
            property="og:url"
            content={`${currentDomain}/answer/${requestId}`}
          />
        </Helmet>
      )}

      <Box
        sx={{
          width: "100%",
          height: "100vh",
          backgroundColor: "#F8F6F4",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          alt="Brand logo"
          sx={{
            position: "absolute",
            top: "20px",
            left: "20px",
            width: "40px",
            height: "40px",
          }}
        >
          <Link to="/">
            <BrandLogo
              sx={{ height: 50, marginRight: "10px", cursor: "pointer" }}
            />
          </Link>
        </Box>

        <Fade in={true} timeout={700}>
          <Container maxWidth="sm" sx={{ textAlign: "center" }}>
            {loading ? (
              <Typography variant="body1">Loading feedback form...</Typography>
            ) : questionData ? (
              <>
                <Typography sx={{ marginBottom: 2, color: "grey.700" }}>
                  <Box
                    component="span"
                    sx={{ fontWeight: "bold", color: "primary.main" }}
                  >
                    {questionData.name}
                  </Box>{" "}
                  would like to ask:
                </Typography>

                <Typography
                  sx={{
                    marginBottom: 2,
                    fontWeight: "bold",
                    fontSize: { xs: "1.6rem", sm: "1.8rem" },
                  }}
                >
                  {questionData.question}
                </Typography>

                <form onSubmit={handleSubmit}>
                  <TextField
                    label="Share your thoughts"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                    required
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "20px",
                    }}
                    slotProps={{
                      htmlInput: {
                        maxLength: maxCharacters,
                      },
                      input: {
                        disableUnderline: true,
                        sx: {
                          borderRadius: "20px",
                          border: "1px solid transparent",
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      },
                      inputLabel: {
                        sx: {
                          color: "grey.500",
                        },
                      },
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      color: "text.secondary",
                      marginTop: 1,
                      marginBottom: 2,
                    }}
                  >
                    {answer.length}/{maxCharacters} characters
                  </Typography>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      padding: 1.5,
                      borderRadius: "50px",
                      textTransform: "none",
                      width: "100px",
                      backgroundColor: "primary",
                      color: "white",
                    }}
                  >
                    <CheckIcon />
                  </Button>
                  <Typography
                    variant="body2"
                    sx={{ marginTop: 4, color: "grey.700", fontSize: "0.8rem" }}
                  >
                    Please answer before {resultDate}. Your answer is totally
                    anonymous.{" "}
                    {questionData.save_exact_feedback ? (
                      <>
                        <Box component="span" sx={{ fontWeight: "bold" }}>
                          As requested, the feedback seeker can see your
                          feedback in its original wordings.
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box component="span" sx={{ fontWeight: "bold" }}>
                          The feedback seeker cannot see your feedback in its
                          original wordings but only a summarized report.
                        </Box>
                      </>
                    )}{" "}
                    <Link href="/" target="_blank" underline="hover">
                      Click here to learn more about our feedback process.
                    </Link>
                  </Typography>
                </form>
              </>
            ) : (
              <Typography variant="body1">Feedback form not found</Typography>
            )}
          </Container>
        </Fade>
      </Box>
    </div>
  );
};

export default AnswerPage;
