// src/theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      light: "#90caf9", // Blue light
      main: "#1976d2", // Blue main
      dark: "#0d47a1", // Blue dark
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#4dd0e1", // Teal light
      main: "#00838f", // Teal main
      dark: "#005662", // Teal dark
      contrastText: "#ffffff",
    },
    background: {
      default: "#fafafa", // Off-white for backgrounds
    },
  },
  typography: {
    fontFamily: [
      '"Noto Sans", sans-serif',
      "Roboto",
      "Courgette",
      '"Nerko One"',
    ].join(","),
  },
});

export default theme;
